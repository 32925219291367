<template>
  <div class="userLabelForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item
        label="用户标签名称"
        :rules="[
          { required: true, message: '请输入用户标签名称', trigger: 'blur' },
        ]"
        prop="labelName"
      >
        <v-input
          placeholder="请输入用户标签名称"
          v-model="form.labelName"
        ></v-input>
      </el-form-item>

      <el-form-item label="父级标签名称" prop="labelName">
        <v-input
          placeholder="请输入父级标签"
          disabled
          :value="form.parentName || '--'"
        ></v-input>
      </el-form-item>

      <el-form-item label="父级标签级别" prop="labelLevel">
        <v-input
          placeholder="请输入父级标签"
          disabled
          :value="
            form.labelLevel - 1 === 0 ? '--' : `${form.labelLevel - 1}级标签`
          "
        ></v-input>
      </el-form-item>

      <el-form-item label="标签说明" prop="remarks">
        <v-input
          clearable
          type="textarea"
          v-model="form.remarks"
          :width="500"
          :maxlength="200"
        ></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import {
  getLabelInfoByIdUrl,
  saveLabelInfoUrl,
  getLabelTreeListUrl,
} from "./api.js";
import {} from "./map.js";

export default {
  name: "userLabelForm",
  data() {
    return {
      submitConfig: {
        queryUrl: getLabelInfoByIdUrl,
        submitUrl: saveLabelInfoUrl,
      },
      form: {
        id: undefined,
        labelType: 1,
        labelName: "",
        labelLevel: undefined,
        remarks: "",
        parentId: undefined,
        parentName: "", // 非后端需要参数
      },
    };
  },
  mounted() {
    const { id, labelLevel, parentName, parentId } = this.$route.query;
    if (id !== undefined) {
      this.$refs.formPanel.getData({ id });
    } else {
      this.form.parentId = parentId;
      this.form.parentName = parentName;
    }
    this.form.labelLevel = labelLevel;
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
      });
    },
    submitBefore() {
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.realNameAuditForm {
  box-sizing: border-box;
  height: 100%;
  .formItem {
    ::v-deep .el-form-item__content {
      display: block;
      .vTag {
        margin-right: 20px;
      }
    }
  }
  .address {
    .item {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      .item-l {
        width: 200px;
        font-size: 14px;
        line-height: 16px;
        overflow-x: auto;
      }
      .item-r {
        margin-left: 20px;
        .v-control {
          margin-right: 10px;
        }
      }
    }
  }
  .caritem {
    margin-bottom: 10px;
  }
}
</style>
<style lang="less">
.realNameAuditForm-addAddress .v-control {
  display: flex;
  align-items: center;
}
.realNameAuditForm-addAddress .v-control label {
  width: 80px !important;
}
</style>
