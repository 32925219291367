import { mapHelper } from "@/utils/common.js";

// 审核状态 0:待审核 1:审核通过 2:审核驳回
const auditStatus = [
  {
    value: 0,
    label: "待审核",
  },
  {
    value: 1,
    label: "审核通过",
  },
  {
    value: 2,
    label: "审核驳回",
  },
];

const { map: auditStatusMap, setOps: setAuditStatusOps } =
  mapHelper.setMap(auditStatus);
const realAuditStatus = [
  {
    value: 0,
    label: "待审核",
  },
  {
    value: 1,
    label: "审核通过",
  },
  {
    value: 2,
    label: "审核驳回",
  },
  {
    value: "-1",
    label: "未提交",
  },
];

const { map: realAuditStatusMap, setOps: realAuditStatusOps } =
  mapHelper.setMap(realAuditStatus);
// 性别 F：女性 M：男性
const sex = [
  {
    value: "F",
    label: "女",
  },
  {
    value: "M",
    label: "男",
  },
];

const { map: sexMap, setOps: setSexOps } = mapHelper.setMap(sex);
//积分类型
const pointType = [
  { value: 1, label: "活动获取" },
  { value: 2, label: "扫码获取" },
  { value: 3, label: "未来心愿" },
  { value: 4, label: "社区共建" },
  { value: 5, label: "公益捐赠" },
  { value: 9, label: "积分商城兑换" },
  { value: 8, label: "社区达人申请" },
  { value: 0, label: "积分扫码扣除" },
  { value: 11, label: "用户注册" },
  { value: 12, label: "每日签到" },
];

const { map: pointTypeMap, setOps: pointTypeOps } = mapHelper.setMap(pointType);

const userTypeOptions = [
  {
    value: "10",
    label: "居民",
  },
  {
    value: "11",
    label: "商户",
  },
  {
    value: "21",
    label: "运营人员",
  },
];
const { map: userTypeOptionsMap, setOps: userTypeOptionsOps } =
  mapHelper.setMap(userTypeOptions);
const areaOptions = [
  {
    value: "10",
    label: "小区",
  },
  {
    value: "11",
    label: "区域",
  },
  {
    value: "21",
    label: "机构类型",
  },
];

const { map: areaOptionsMap, setOps: areaOptionsOps } =
  mapHelper.setMap(areaOptions);
export {
  auditStatusMap,
  setAuditStatusOps,
  sexMap,
  setSexOps,
  pointTypeMap,
  pointType,
  userTypeOptions,
  userTypeOptionsMap,
  areaOptionsMap,
  realAuditStatus,
  realAuditStatusMap,
};
